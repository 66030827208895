window.VWFS = window.VWFS || {};
VWFS.ETrustedWidget = function () {

    this.init = function () {
        let widgets = document.querySelectorAll(".vwfs-dmp-etrusted-widget");
        if (widgets.length > 0) {
            let script = document.createElement('script');
            script.src = "https://integrations.etrusted.com/applications/widget.js/v2";
            document.head.appendChild(script);
        }
    }

}

document.addEventListener("DOMContentLoaded", function (event){
    console.debug("INFO eTrusted/widget.js loaded.");

    new VWFS.ETrustedWidget().init();
})
